#scan {
  text-align: center;
}

#intro h1 {
  font-size: 150%;
  margin-top: 20px;
}

#intro h2 {
  font-size: 130%;
}

#intro .faq {
  max-width: 500px;
  margin: 10px auto;
  text-align: left;
  border: 1px solid black;
  border-radius: 25px;
  padding: 20px;
  background: #f8f8c0;
}

#intro .faq a, #intro .faq a:visited {
  color: #24c;
}

#qrcode {
  margin: 10px;
}

#qrcode canvas {
  width: 100%;
  height: auto;
}

#info h2 {
  font-size: 130%;
  border-bottom: 2px solid black;
  margin: 10px;
}

#info table {
  width: 100%;
  margin: 10px;
}

#info table th {
  text-align: right;
  width: 50%;
  padding-right: 10px;
}

#info table td {
  text-align: left;
  width: 50%;
  padding-left: 10px;
}

#info table td span.Validated {
  background: #0a0;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 140%;
  color: white;
}

#info table td span.NotValidated {
  background: #c00;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 140%;
  color: white;
}

#info table td span.OK {
  background: #0a0;
  padding: 3px 8px;
  font-weight: bold;
  color: white;
}

#info table td span.Expired {
  background: #c00;
  padding: 3px 8px;
  font-weight: bold;
  color: white;
}

button {
  margin-bottom: 10px;
}
